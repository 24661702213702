<template>
  <div v-if="needRefresh" class="pwa-toast z-50" role="alert">
    <BrandLogo class="mb-1" />

    <div class="message mt-2">
      <UiLoader v-if="updating" label="Updating your Mixo experience. Hold tight..." />
      <span v-else>A new version of the app is available. Click on the reload button to update.</span>
    </div>

    <div v-if="!updating">
      <UiButton theme="brand-primary" @click="onUpdate">
        Reload
      </UiButton>
      <UiButton theme="outline" @click="close">
        Close
      </UiButton>
    </div>
  </div>
</template>

<script setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import { track } from "@/lib/tracking";
const { needRefresh, updateServiceWorker } = useRegisterSW();

const updating = ref(false);

async function close() {
  needRefresh.value = false;
}

async function onUpdate() {
  updating.value = true;
  await updateServiceWorker();
  close();
  updating.value = false;
  
  // Get the frontend version from the environment variables and trigger mixpanel event
  const frontendVersion = import.meta.env.VITE_GITHUB_SHA || import.meta.env.VITE_APP_ENV;
  track("App Updated", { frontendVersion })

  // Reload the page after the service worker has been updated but check with the user first
  if (confirm("The app has been updated. Reload now?")) {
    location.reload();
  }
}
</script>

<style>
.pwa-toast {
  @apply bg-white shadow-md border border-gray-300 rounded-lg p-4 fixed bottom-0 right-0 m-4 text-slate-800;
}

.pwa-toast .message {
  margin-bottom: 8px;
}

.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 8px;
  padding: 3px 10px;
}
</style>
