import { get, isEmpty } from 'lodash-es'
import { callableFunction, callableExtensions } from "@/lib/utils.js";
import { getAll as getPlans } from '@/lib/data/plans'
import { getClientReferenceId } from "@/utils/rewardful";
import { logger } from "@/utils/index.js";

// checks the site to see if it has access to a feature
const subscriptionCheck = async (site) => {
  // check to see if site contains a subscription object with a valid planId
  const planId = get(site, 'subscription.planId', null);

  if (isEmpty(planId)) {
    return {
      isPaid: false,
    }
  }

  const plans = await getPlans();
  const plan = plans.find(plan => plan.id === planId); 
  
  // extract the maxPagesPublish and maxPagesGenerate from the plan metaData
  const maxPagesPublish = get(plan, 'metaData.maxPagesPublish', 0);
  const maxPagesGenerate = get(plan, 'metaData.maxPagesGenerate', 0);
  
  return {
    isPaid: false,
    usages:{
      pagesPublished: {
        used: 0,
        max: maxPagesPublish,
      },
      pagesGenerated:{
        used: 0,
        max: maxPagesGenerate,
        resetDate: null,
      }
    }
  }
}

const getCustomPortalUrl = async (returnUrl) => {
  try {
    const data = await callableExtensions('ext-firestore-stripe-payments-createPortalLink', {
      returnUrl,
      locale: 'auto', // Optional, defaults to "auto"
    })
    return data?.url
  } catch (error) {
    logger.error('getCustomPortalUrl failed', error)
    return false;
  }
}

/**
 * Creates a checkout session
 *
 * @param {*} priceId The price id to create a checkout session for
 * @param {*} options  includes siteId, subscriptionId, promotionCodeId
 * @returns {Object} Checkout session object
 */
const createCheckout = async (priceId, options) => {
  try{
    const params = {
      priceId,
      hostUrl: `${window.location.protocol}//${window.location.host}`,
      cancelUrl: `${window.location.origin}${window.location.pathname}?ref=PaymentCancel`,
    };

    // Stripe Checkout will raise an error if clientReferenceId is set to a blank value so we add it here. client_reference_id is used by rewardful to track partners
    const clientReferenceId = getClientReferenceId()
    if (clientReferenceId) params.clientReferenceId = clientReferenceId

    // check options for additional params we can pass through
    if(options?.siteId) params.siteId = options.siteId;
    if(options?.subscriptionId) params.subscriptionId = options.subscriptionId;
    if(options?.promotionCodeId) params.promotionCodeId = options.promotionCodeId;
    if(options?.trialPeriodDays) params.trialPeriodDays = options.trialPeriodDays;
    if(options?.creditCardNotRequired) params.creditCardNotRequired = options.creditCardNotRequired;
  

    // set defaults for purchases
    params.adjustableQuantity = false;
    params.includeCustomQuestions = false;

    // Create a reference to the callable function "createCheckout"
    return callableFunction("createCheckout", params);
  } catch(error) {
    logger.error("createCheckout failed", error);
  }
};

export {
  subscriptionCheck,
  getCustomPortalUrl,
  createCheckout
}
