import { defineAsyncComponent  } from "vue"; //, defineEmits
import { hideModal, showModal } from "@/composables";
import { asyncComponentDefaults } from "^/composables/asyncComponent";
import { getCustomPortalUrl } from "@/lib/plans";
import { logger } from "@/utils/index.js";

const PlanSelector = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/PlanSelector/PlanSelector.vue"),
});

const UiLoaderCenter = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("^/components/ui/UiLoader/UiLoaderCenter.vue"),
});

const DowngradePlan = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/DowngradePlan/DowngradePlan.vue"),
});

export const usePlans = ({ openAuthModal }) => {
  const onUpgrade = async (site) => {
    const siteId = site?.id;

    if(!siteId) {
      logger.log("onUpgrade called without siteId!");
    }

    showModal(
      { name: "plan-selector" },
      {
        default: {
          component: PlanSelector,
          bind: {
            openAuthModal: openAuthModal,
            siteId: siteId,
            site: site,
            trial: false // upgrading should never need a trial
          },
        },
      }
    );
  };

  const onDowngrade = async (siteId, subscriptionId, callback) => {
    showModal(
      { id: "loader", name: "loader", clickToClose: true, showClose: false },
      {
        default: {
          component: UiLoaderCenter,
          bind: {
            label: "Checking Plan Settings...",
          },
        },
      }
    );

    const url = await getCustomPortalUrl(window?.location?.origin);

    hideModal("loader");

    showModal(
      { name: "downgrade-site-confirm", actions: false },
      {
        default: {
          component: DowngradePlan,
          bind: {
            url
          },
          on: {
            confirm() {
              if (callback && typeof callback === "function") {
                callback();
              }

              hideModal("downgrade-site-confirm");
            },
            cancel() {
              hideModal("downgrade-site-confirm");
            },
          }
        },
      },
    );
  };

  const planClasses = {
    Free: "text-gray-600 bg-gray-200",
    "Basic Site": "text-yellow-700 bg-yellow-200",
    "Growth Site": "text-cyan-700 bg-cyan-200",
    "Premium Site": "text-indigo-700 bg-indigo-200",
    "Pro": "text-yellow-700 bg-yellow-200 before:content-['Legacy_']",
    "Basic": "text-yellow-700 bg-yellow-200 before:content-['Legacy_']",
    "Starter": "text-yellow-700 bg-yellow-200 before:content-['Legacy_']",
  };

  return { onDowngrade, onUpgrade, planClasses };
};
