import { asyncComponentDefaults, showModal } from "@/composables";
import { defineAsyncComponent, inject } from "vue";


// const DomainSettings = defineAsyncComponent({
//   ...asyncComponentDefaults,
//   loader: () => import("@/components/DomainSettings/DomainSettings.vue"),
// });

const CustomizeDomain = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Domains/DomainCustomize.vue"),
});

const DomainBuy = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Domains/Buy/DomainBuy.vue"),
});

const DomainSearch = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Domains/Search/DomainSearch.vue"),
});


export const useCustomizeDomain = () => {
  const openAuthModal = inject("openAuthModal");

  const openCustomizeDomain = (site) => {
    showModal(
      { name: "custom-domain", contentClasses: "w-full max-w-3xl" },
      {
        default: {
          component: CustomizeDomain,
          bind: {
            openAuthModal,
            site,
          },
        },
      }
    );
  };

  return { openCustomizeDomain }
}

export const useDomainSearch = () => {
  const openDomainSearch = (siteName, siteId) => {
    showModal(
      { name: "domain-search", contentClasses: "w-full max-w-3xl" },
      {
        default: {
          component: DomainSearch,
          bind: {
            siteId,
            siteName,
          },
        },
      }
    );
  };

  return { openDomainSearch }
}

export const useDomainBuy = () => {
  const openDomainBuy = (domain, siteId) => {
    showModal(
      { name: "domain-buy", contentClasses: "w-full max-w-4xl" },
      {
        default: {
          component: DomainBuy,
          bind: {
            siteId,
            domain,
          },
        },
      }
    );
  };

  return { openDomainBuy }
}